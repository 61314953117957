import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import HalfContentHalfImage from "../components/HalfContentHalfImage"

const ServicePage = ({ data }) => {
  const content = data.allPrismicServicesPage.edges[0].node.data.content_section
  return (
    <Layout>
      <SEO title="Services" />
      <div className="services-page">
        {content.map((section, i) => {
          return (
            <HalfContentHalfImage
              key={i}
              title={section.title}
              content={section.content}
              image={section.image}
              contentLeft={i % 2}
            />
          )
        })}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ServicesPageQuery {
    allPrismicServicesPage {
      edges {
        node {
          data {
            content_section {
              content {
                html
              }
              image {
                fluid(maxWidth: 1000) {
                  src
                  srcWebp
                  srcSetWebp
                  srcSet
                  sizes
                  base64
                  aspectRatio
                }
              }
              title {
                text
              }
            }
          }
        }
      }
    }
  }
`
export default ServicePage
